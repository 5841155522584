import React from 'react';
import Dashboard from '@/pages/Dashboard';

function App() {
  return (
    <Dashboard />
  );
}

export default App;
